import React, { useState, useCallback, useEffect } from "react"
import MenuItem from "./menu-item"
import { StaticQuery, graphql } from "gatsby"
import Animated from "animated/lib/targets/react-dom"
import Logo from "./logo"
import { useScroll } from "../utils/useScroll"
import Button from "./Button"
import styled from "styled-components"
import MediaQuery from "react-responsive"
import { Menu, MenuOverlay, MenuOverlayImage, MenuTrigger } from "./hamburger"

const _window = typeof window !== "undefined" ? window : {}

function getSize() {
  return {
    innerHeight: _window.innerHeight,
    innerWidth: _window.innerWidth,
    outerHeight: _window.outerHeight,
    outerWidth: _window.outerWidth,
  }
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize())

  function handleResize() {
    setWindowSize(getSize())
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return windowSize
}

const HeaderWrapper = styled.header`
  display: flex;
  height: 100px;
  zindex: 100;
  flex-direction: column;
  align-items: center;
`

const Header = () => {
  const scrollTo = useScroll()
  const size = useWindowSize()

  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <HeaderWrapper id="header">
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                menu
              }
            }
            background: file(relativePath: { eq: "background.png" }) {
              sharp: childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <ul
              style={{
                width: "1280px",
                alignItems: "stretch",
                position: "relative",
                justifyContent: "flex-end",
                margin: 0,
                height: "100%",
                listStyle: "none",
                display: "flex",
              }}
            >
              <li
                style={{
                  position: "absolute",
                  left: 35,
                  top: 20,
                  transform: "scale(0.22)",
                  transformOrigin: "0 0",
                }}
              >
                <Logo animation={new Animated.Value(0)} />
              </li>
              <MediaQuery query="(min-width: 900px)">
                {data.site.siteMetadata.menu.map((item, i) =>
                  item !== "Магазин" ? (
                    <MenuItem
                      key={item}
                      onClick={() => {
                        scrollTo(item)
                      }}
                    >
                      {item}
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>
                      <Button
                        as={"a"}
                        href={"http://google.com"}
                        target={"_blank"}
                      >
                        {" "}
                        {item}{" "}
                      </Button>
                    </MenuItem>
                  )
                )}
              </MediaQuery>
            </ul>
            <MediaQuery query="(max-width: 900px)">
              <Menu>
                <MenuOverlay
                  landscape={size.innerWidth - size.innerHeight > 0}
                  open={open}
                >
                  <MenuOverlayImage src={data.background.sharp.fluid.src}>
                    {data.site.siteMetadata.menu.map((item, i) =>
                      item !== "Магазин" ? (
                        <MenuItem
                          key={item}
                          onClick={() => {
                            toggle()
                            scrollTo(item)
                          }}
                        >
                          {item}
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>
                          <Button
                            as={"a"}
                            href={"http://google.com"}
                            target={"_blank"}
                          >
                            {" "}
                            {item}{" "}
                          </Button>
                        </MenuItem>
                      )
                    )}
                  </MenuOverlayImage>
                </MenuOverlay>
                <MenuTrigger onClick={toggle}>
                  <div
                    className={`hamburger hamburger--slider ${
                      open ? "is-active" : ""
                    }`}
                  >
                    <div className="hamburger-box">
                      <div className="hamburger-inner"></div>
                    </div>
                  </div>
                </MenuTrigger>
              </Menu>
            </MediaQuery>
          </>
        )}
      />
    </HeaderWrapper>
  )
}

export default Header
