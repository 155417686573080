import React from 'react'
import Background from './background'
import { Background as Bg } from './contacts-background'
import Content from './content'
import HomeBubble from './home-bubble'
import Logo from './logo'
import styled from 'styled-components'

const LogoWrapper = styled.div`
  position: absolute;
  width: 35vh;
  max-width: 250px;
  top: 50px;
  left: 35px;

  > div {
    width: 100%;
    padding-top: 130%;
  }

  svg {
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
`

const HomeWrapper = styled.section`
  width: 100%;

  @media (max-width: 900px) {
    ${Bg} {
      height: 500px;
      margin-left: -160px;
      svg {
        height: 100%;
      }
    }
  }
`

const HomeContent = styled(Content)`
  position: relative;
  height: 100%;
  padding-top: 68vh;

  p {
    width: 840px;
    text-align: center;
    color: #707070;
  }

  @media (max-width: 415px) {
    padding-top: 100vw;
    ${LogoWrapper} {
      left: 50px !important;
      top: 40px !important;
    }
  }

  @media (max-width: 900px) {
    padding-top: 40vh;

    p {
      width: 100%;
      padding: 0 50px;
    }

    ${LogoWrapper} {
      left: 100px;
      top: 30px;
      width: 80px;
    }
  }
`

const Home = ({ id }) => (
  <HomeWrapper id={id}>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      }}
    >
      <Background />
      <HomeBubble x={260} top={'15vh'} style={{ zIndex: 0 }} />
      <HomeContent>
        <LogoWrapper>
          <div>
            <Logo />
          </div>
        </LogoWrapper>
        <h2>За нас</h2>
        <p>
          Фирма So Far се занимава с проектиране, изработка, монтаж и демонтаж на реклами и рекламни
          съоражения. Материалната база се намира в град София, наши мобилни екипи извършват дейност
          в цялата страна. Нашият колектив се състои от професионалисти и специалисти в областта на
          рекламата и рекламните съоражения.
        </p>
      </HomeContent>
    </div>
  </HomeWrapper>
)

export default Home
