import styled from "styled-components"
import { ButtonWrapper } from "./button"

const MenuItem = styled.li`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: flex;
  margin: 0 15px;
  text-transform: uppercase;
  align-items: center;
  color: #fff;
  font-size: 16px;

  a {
    min-width: 0;
    margin: 0px;
    font-size: 16px;
    padding: 4px 18px;
    height: auto;
    border-radius: 10px;
  }
`

export default MenuItem
