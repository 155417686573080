import React from "react"
import styled from "styled-components"

export const ButtonWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  background: #ff824e;
  color: rgba(255, 255, 255, 0.9);
  height: 57px;
  font-size: 16px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  min-width: 200px;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(255, 130, 78, 0.4);
  ${({ disabled }) =>
    disabled &&
    `
  cursor: not-allowed;
  background: #AAAAAA;
  box-shadow: none;
  `}

  :hover {
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 30px rgba(255, 130, 78, 0.4);
    ${({ disabled }) => disabled && `box-shadow: none`}
  }
`

const Button = ({ children, onClick, disabled, ...props }) => (
  <ButtonWrapper
    {...props}
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
  >
    {children}
  </ButtonWrapper>
)

export default Button
