import React from "react"
import { Background } from "./contacts-background"

const HomeBubble = props => (
  <Background {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="940"
      height="940"
      viewBox="0 0 940 940"
    >
      <g transform="translate(-82 -83)">
        <circle
          cx="470"
          cy="470"
          r="470"
          transform="translate(82 83)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <circle
          cx="65"
          cy="65"
          r="65"
          transform="translate(82 318)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  </Background>
)

export default HomeBubble
