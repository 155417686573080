import { useRef, useEffect, useCallback } from "react"
import useSmoothScroll from "use-smooth-scroll"

export const useScroll = () => {
  const ref = useRef()
  useEffect(() => {
    ref.current = windowRef
  })
  const _scrollTo = useSmoothScroll("y", ref)

  return useCallback(id => {
    const el = document.getElementById(id)

    if (!el) {
      return
    }

    _scrollTo(el.offsetTop)
  })
}

const _window =
  typeof window !== "undefined"
    ? window
    : {
        scrollTo() {},
        addEventListener() {},
        removeEventListener() {},
      }

const windowRef = {
  _ref: _window,
  set scrollTop(value) {
    _window.scrollTo(window.scrollX, value)
  },
  get scrollTop() {
    return _window.scrollY
  },
  set scrollLeft(value) {
    _window.scrollTo(value, window.scrollY)
  },
  get scrollLeft() {
    return _window.scrollX
  },
  addEventListener(...args) {
    return _window.addEventListener.call(window, ...args)
  },
  removeEventListener(...args) {
    return _window.removeEventListener.call(window, ...args)
  },
}
