import styled from "styled-components"
import MenuItem from "./menu-item"

export const Menu = styled.div`
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const MenuOverlayImage = styled.ul`
  position: absolute;
  right: 50%;
  top: 50%;
  margin-top: -45px;
  margin-right: -45px;
  background: url(${({ src }) => src});
  background-position: 50% 50%;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MenuItem} {
    padding: 20px;
  }
`

export const MenuOverlay = styled.div`
  pointer-events: all;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: red;
  overflow: hidden;
  border-radius: 100000000000px;
  transition: all 0.5s ease-in-out, opacity 0.05s ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${({ open = false, landscape = false }) =>
    open
      ? `
      opacity: 1;
    width: ${landscape ? "300vw" : "300vh"};
    height: ${landscape ? "300vw" : "300vh"};
    top: calc(${landscape ? "-150vw" : "-150vh"} + 45px);
    right: calc(${landscape ? "-150vw" : "-150vh"} + 45px);
    `
      : `
      opacity: 0;
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
    `}
`

export const MenuTrigger = styled.div`
  pointer-events: all;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 26px;
  line-height: 47px;
  cursor: pointer;

  .hamburger {
    margin: -10px;
  }

  .hamburger-inner {
    background-color: #fff !important;
    :after,
    :before {
      background-color: #fff !important;
    }
  }
`
