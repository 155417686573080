import React from "react"
import styled from "styled-components"

export const Background = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: -1;
  ${props =>
    props.top
      ? `top: ${props.top};`
      : props.bottom
      ? `bottom: ${props.bottom};`
      : `top: ${props.y || 0}px;`}
  ${props =>
    props.align === "left"
      ? "left: 0;"
      : props.align === "right"
      ? "right: 0;"
      : "left: 50%;"}
  margin-${props => props.align || "left"}: ${props => props.x || 0}px;
`

const ContactsBackground = props => (
  <Background {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="988"
      height="1233.999"
      viewBox="0 0 988 1233.999"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.381"
          y1="0.162"
          x2="0.693"
          y2="0.963"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#ff824e" stopOpacity="0.502" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.381"
          y1="0.162"
          x2="0.615"
          y2="0.734"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#ffece4" />
        </linearGradient>
      </defs>
      <g transform="translate(-1022 -170)">
        <circle
          cx="494"
          cy="494"
          r="494"
          transform="translate(1022 170)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <circle
          cx="209"
          cy="209"
          r="209"
          transform="translate(1161.913 985.999)"
          fill="url(#linear-gradient-2)"
        />
      </g>
    </svg>
  </Background>
)

export default ContactsBackground
