import React, { useEffect, useCallback, useState } from "react"
import styled from "styled-components"

const Spacer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: ${({ offset = 0, fly }) => (fly ? `${offset}px` : "auto")};
`

const Header = styled.div`
    z-index: 999;
    width: 100%;
    background-position: 50% 0;
    transition: transform 100ms ease-in;
    ${({ fly }) => (fly ? "position: fixed;" : "position: static;")}
    ${({ fly, url }) => fly && `background-image: url('${url}');`}
        ${({ position = "show", fly = false }) =>
          fly && position === "hide"
            ? `transform: translateY(-100%);`
            : `transform: translateY(0);`}

            svg {
                opacity: ${({ fly }) => (fly ? 1 : 0)};
              }
`

const StickyHeader = ({ url, offset = 100, children }) => {
  let lastScroll = 0
  const [position, setPosition] = useState("hide")
  const [fly, setFly] = useState(false)

  const onScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (Math.abs(lastScroll - window.scrollY) <= 5) {
        return
      }
      setFly(window.scrollY > offset)
      if (lastScroll < window.scrollY) {
        setPosition("hide")
      } else {
        setPosition("show")
      }
      lastScroll = window.scrollY
    })
  }, [lastScroll])

  useEffect(() => {
    window.addEventListener("mousewheel", onScroll)
    window.addEventListener("scroll", onScroll)
    window.addEventListener("DOMMouseScroll", onScroll)

    return () => {
      window.removeEventListener("mousewheel", onScroll)
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("DOMMouseScroll", onScroll)
    }
  }, [])
  return (
    <Spacer fly={fly} offset={offset}>
      <Header url={url} fly={fly} position={position}>
        {children}
      </Header>
    </Spacer>
  )
}

export default StickyHeader
